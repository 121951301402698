import React from "react"
import Tilt from "react-parallax-tilt"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/seo"

import Layout from "../components/layout"

import "../styles/main.scss"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      ImageHero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
      ImageHeroTilt: file(relativePath: { eq: "hero--tilt.png" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
      ImageLogo: file(relativePath: { eq: "logo__kevinheil.png" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }

      ImageBrilleVektorDetailliert: file(
        relativePath: { eq: "3d-glasses--detailed.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
      ImageBesserePlaylist: file(relativePath: { eq: "bessereplaylist.png" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
      ImageBestePlaylist: file(relativePath: { eq: "besteplaylist.png" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
      ImageHyped: file(relativePath: { eq: "hyped.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpSizes_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Kevin Heil -  Code, Design & Moderation" />
      <header className="pageheader" id="nav">
        <div>
          <div className="tiltimage-wrapper">
            <Image
              fluid={data.ImageHero.childImageSharp.sizes}
              alt="Mann mit Bart und Cap"
            />

            <div className="tiltimage">
              <Tilt
                className="tilt-img"
                tiltMaxAngleX={20}
                tiltMaxAngleY={20}
                perspective={500}
                scale={1.1}
                transitionSpeed={1000}
                gyroscope={true}
              >
                <Image
                  fluid={data.ImageHeroTilt.childImageSharp.sizes}
                  alt="Mann mit Bart und Cap"
                  className="inner-element"
                />
              </Tilt>
            </div>
          </div>
          <h1 className="logo">
            <Image
              fluid={data.ImageLogo.childImageSharp.sizes}
              alt="Kevin Heil geschrieben mit einem Pinsel"
            />
          </h1>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#grafik" className="deformed bookmarks">
                <span>Grafik & Entwicklung</span>
              </a>
            </li>
            <li>
              <a href="#moderation" className="deformed bookmarks">
                <span>Moderation</span>
              </a>
            </li>
            <li>
              <a href="#musik" className="deformed bookmarks">
                <span>Musik</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <section>
        <article className="article">
          <h2>Watt soll der Bums?</h2>
          <h1>Und warum ist er so bunt?!</h1>
          <p>
            Seit über 15 Jahren finde ich nun im Internet statt. Sei es im
            professionellen Rahmen als Grafiker und Entwickler,
            semi-professionell als Bühnenkünstler, Sprecher und Moderator oder
            auch überwiegend zum Spaß als Teil eines Podcast Ensembles, Gamer &
            Streamer.
            {/* oder als Privatperson auf der Suche nach Zerstreuung und
            Nachrichten. */}
          </p>
          <p>
            Dabei habe ich stets darauf geachtet das all diese "Personas" ihren
            eigenen Raum bekommen und sie untereinander auch nicht aufeinander
            verlinkt. Damit ist Schluss und diese Webseite fungiert als Hub.
          </p>

          <Image
            fluid={data.ImageBrilleVektorDetailliert.childImageSharp.sizes}
            alt="3D Brille mit einem blauen und einem roten Glas."
          />

          <p>
            Ich bin Kevin Heil und was ich tue ist weit mehr als nur die Summe
            aller Teile. So wie verschiedene Farbkanäle gemeinsam ein komplettes
            Bild ergeben, welches dann eine Geschichte erzählt, so ist die
            Person Kevin Heil im professionellem wie im persönlichen Umfeld mehr
            als ein Ergebnis aus allen Teilbereichen. Deshalb ist der "Bums"
            hier so bunt.
          </p>
          <p>
            Damit meine ich, dass Workshops, die ich leite, unweigerlich von
            meinem Dasein als Moderator profitieren und vice versa. Die
            Angewohnheit als Entwickler und Grafiker permanent Grundsätzliches
            in Frage zu stellen sorgt im Gegenzug für interessante Interviews im
            Radio.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Hire me
          </a>
        </article>
      </section>

      <section id="grafik">
        <header className="sectionheader">
          <h1>Grafik & Entwicklung</h1>
          <p>
            Seit über einem Jahrzehnt konnte ich bisher "in den Medien"
            Erfahrung sammeln. Mindestens die Hälfte davon in branchenführenden
            Unternehmen wie hmmh/plan.net & Eventim. Den Rest als
            Geschäftsführender Gesellschafter einer eigenen Agentur sowie als
            Freelancer.
          </p>
          <p>
            Dabei habe ich von der Pike auf nicht nur sämtliche Arbeiten eines
            Grafikers und Frontend Entwicklers gelernt, sondern auch alle
            weiteren Teile der Wertschöpfungskette verinnerlicht.
          </p>
          <p>
            In dieser Zeit wurden von mir nationale wie internationale Firmen,
            Einzelhändler:innen genauso wie Konzerne beraten, beliefert &
            betreut.
          </p>
          <p>
            Arbeitsweisen sind mir wichtiger als Programmiersprachen und
            langfristige Partnerschaften eher das Ziel als lukrative,
            kurzfristige Projektgeschäfte.
          </p>

          <nav>
            <ul>
              <li>
                <a
                  href="#motivation"
                  title="Zum Bereich Motivation"
                  className="deformed bookmarks"
                >
                  Motivation
                </a>
              </li>
              <li>
                <a
                  href="#wiewowas"
                  title="Zum Bereich Wie wo was?"
                  className="deformed bookmarks"
                >
                  Wie wo was?
                </a>
              </li>
              <li>
                <a
                  href="#methodik"
                  title="Zum Bereich Methodik"
                  className="deformed bookmarks"
                >
                  Methodik
                </a>
              </li>
              <li>
                <a
                  href="#wiekannichhelfen"
                  title="Zum Bereich Wie kann ich helfen?"
                  className="deformed bookmarks"
                >
                  Wie kann ich helfen?
                </a>
              </li>
              <li>
                <a
                  href="#workshops"
                  title="Zum Bereich Workshops & Vorträge"
                  className="deformed bookmarks"
                >
                  Workshops & Vorträge
                </a>
              </li>
              <li>
                <a
                  href="#moderation"
                  title="Zum nächsten Kapitel"
                  className="deformed bookmarks"
                >
                  Fast Forward ⇥
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <article className="article" id="motivation">
          <h2>Ich mach uns die Welt, widewide wie sie uns gefällt</h2>
          <h1>Motivation</h1>
          <p>
            Bedeutend häufiger als ich sollte, bin ich genervt von (schlechten)
            Interfaces.
          </p>
          <p>
            Eine einfache Aufgabe wie eine Überweisung kann zu einem
            halbstündigen Martyrium werden, wenn die Banking Oberfläche zwar die
            internen Bankabläufe darstellt und erfüllt, aber niemand bedenkt
            dass der Endkunde kein Bankier ist.
          </p>
          <p>
            Da mir meine Lebenszeit um einiges wertvoller ist, als sie mit der
            Suche nach der richtigen Reihenfolge der Knöpfe zu verschwenden,
            möchte ich Teil von Teams sein, die sich darum kümmern, dass wir uns
            alle mehr um das kümmern können, was wir leben nennen.
          </p>
          <p>
            <a href="mailto:moin@kevinheil.de">
              Mein Skillset nutzen, um sinnvolles, Mehrwert bietendes,
              nutzerzentriertes Zeugs zu erschaffen. ↣
            </a>
          </p>
        </article>
        <article className="article" id="wiewowas">
          <h2>Webseiten, Apps, Shops, CMS – u name it</h2>
          <h1>Wie wo was?</h1>
          <p>
            Bei der Gestaltung und Umsetzung von User Interfaces aka
            Benutzeroberflächen geht es immer darum, Probleme zu lösen und/oder
            Bedürfnisse zu befriedigen.
          </p>
          <p>
            Dabei ist mir die zugrunde liegende Technologie egal. Ob der Shop in
            Java oder PHP geschrieben ist, die Software sich aus Microservices
            zusammenstöpselt oder die App mit einem JavaScript Framework
            umgesetzt wurde, ist zweitrangig. Wichtiger ist, dass diejenigen,
            die sie später benutzen müssen, schnellstmöglich ihr Ziel erreichen.
            Sprache & Frameworks sind lediglich Vehikel dafür.
          </p>
          <p>
            Dennoch ist es sinnvoll, sich vorher ausführlich Gedanken darüber zu
            machen, was die eigene Applikation können soll und ob dies evtl. mit
            einem bereits bestehenden Toolset
            schneller/besser/einfacher/günstiger umzusetzen ist. Auch hierbei
            kann ich helfend zur Seite stehen.
          </p>
          <p>
            P.S. Eine App programmieren zu lassen nur weil der Mitbewerber eine
            gelauncht hat ist nie sinnvoll.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Lasst uns miteinander sprechen
          </a>
        </article>
        <article className="article" id="methodik">
          <h2>Buzzwordbingo</h2>
          <h1>Methodik</h1>
          <p>
            Menschen und ihr Verhalten in den Fokus stellen, dies erforschen &
            messen, Gemessenes versuchen zu verstehen, Produkt mit den
            Erkenntnissen verbessern aaaaand repeat. #iterativ
          </p>
          <p>
            Mit den richtigen Fragen im Gepäck und unvoreingenommenen
            Schlussfolgerungen, ist es am Ende gar nicht so schwer auch die
            letzten Promille aus der eigenen Software herauszuholen. Meist sind
            es aber bereits die Grundlagen, die dafür sorgen, dass etwas nicht
            so performed wie es soll.
          </p>
          <p>
            Hierfür greife ich auf eine Vielzahl von Tools zurück die auf den
            unterschiedlichsten Flughöhen/Abstraktionsebenen zum Einsatz kommen.
          </p>
          <p>
            Ein Designsystem kann helfen. Test/Behaviour Driven
            Development/Design kann helfen. Designsprints können helfen.
            Interdisziplinäre Teams & Content First können helfen.
            Kanban/Scrum/Agile kann helfen. Block__Element--Modifier kann
            helfen. Performancebudgets können helfen. Die Entwicklung einer
            bereichsübergreifenden Produktvision kann helfen.{" "}
            <strong>Muss aber nicht.</strong>
          </p>
          <p>
            Letztendlich kann nur eine ausführliche Einzelfallbetrachtung,
            abhängig von der Aufgabenstellung, helfen.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Ich kann helfen!
          </a>
        </article>
        <article className="article" id="wiekannichhelfen">
          <h2>Hilfe zur Selbsthilfe</h2>
          <h1>Wie ich helfen kann</h1>
          <p>
            In meiner Rolle als User Interface Engineer erdenke ich
            Benutzeroberflächen und setze sie um. Dabei berücksichtige ich
            unterschiedlichste Einflussfaktoren wie Wartbarkeit, Performance,
            User Experience, Zukunftssicherheit, Budget uvm.
          </p>
          <p>
            Ich kann als Externer frischen Wind in euer bestehendes Team wehen,
            als Solo Freelancer MVPs Live bringen oder in beratender Tätigkeit
            oder als Sparringspartner helfen - vom Software Stack bis hin zur
            Architektur - die für euch richtigen Entscheidungen zu finden.
          </p>
          <p>
            Dabei stütze ich mich auf über ein Jahrzehnt an Erfahrung und mein
            interdisziplinäres Skillset bestehend aus Design und Entwicklung.
            Die Auswirkungen meines Handelns können vielfältig sein, sofern man
            mich lässt. Denn auch stumpfes Umsetzen von bereitgestellten Designs
            ist mir nicht unbekannt, jedoch in keinster Weise eine erfüllende
            Aufgabe.
          </p>
          <p>Gutes entsteht nur im Dialog.</p>
          {/* , jedoch sorgt dies lediglich für das Essen
            auf meinem Tisch und nicht dafür das die Flamme in mir am brennen
            gehalten wird. Weshalb bei solchen Beauftragungen nicht davon
            ausgegangen werden muss das ich mich vollumfänglich einbringe. */}

          <a href="mailto:moin@kevinheil.de" className="button">
            Hire me
          </a>
        </article>
        <article className="article" id="workshops">
          <h2>And if you don't know, now you know</h2>
          <h1>Workshops, Schulungen & Vorträge</h1>
          <p>
            Seit Tag 1 ist es mir besonders wichtig erlangtes Wissen weiter zu
            geben. Auch deshalb habe ich mehrere Auszubildende durch ihre
            Ausbildung gebracht und bin mittlerweile Teil des
            Prüfungsausschusses Mediengestalter Digital & Print der IHK Bremen.
          </p>
          <p>
            Da ich nicht besonders gut darin bin nicht technische Themen zu
            verschriftlichen haben sich für mich dialogbezogene Formate über die
            Jahre durchgesetzt weil ich alle meine Stärken in Workshops,
            Schulungen, Vorträgen usw. ausspielen kann ohne das die Menschen
            denen ich das Wissen vermitteln soll vor den Kopf gestoßen werden.
          </p>
          <h4>Schwerpunkte meiner letzten Workshops, Schulungen & Vorträge:</h4>
          <ul>
            <li>Designsysteme</li>
            <li>Wie man als Grafiker Frontend betreibt und vice versa</li>
            <li>Framework spezifische Talks wie Gatsbyjs oder Vuestorefront</li>
          </ul>
          <a href="mailto:moin@kevinheil.de" className="button">
            Each one teach one
          </a>
        </article>
      </section>

      <section id="moderation">
        <header className="sectionheader sectionheader--2">
          <h1>Moderation</h1>
          <p>
            Als Bühnenkünstler bin ich zwischen 2009 und 2016 nebenberuflich
            durch Deutschland getourt. In dieser Zeit begann ich hin und wieder
            verschiedene Veranstaltungen zu moderieren bis ich mich endgültig
            für die Rolle des Moderators entschieden habe.
          </p>
          <p>
            Seitdem ist viel passiert. Eine eigene Radiosendung, ein Podcast,
            verschiedene Videoformate, diverse Live Moderationen auf Festivals
            und (Kunst-)Veranstaltungen, Ringsprecher, Podiumsdiskussionsführer,
            Organisation & Moderation von Fachveranstaltungen uvm.
          </p>
          <p>
            Die Arbeit am Mikrofon ist eine willkommene Abwechslung zum Alltag
            hinter dem Bildschirm. Deshalb versuche ich auch hier, wie in allen
            anderen Bereichen, mich kontinuierlich zu verbessern und mein
            Skillset auszubauen, weshalb ich mich seit 2019 zusätzlich zum
            professionellen Sprecher ausbilden zu lasse.
          </p>
          <nav>
            <ul>
              <li>
                <a href="#radio" title="bla" className="deformed bookmarks">
                  Radio
                </a>
              </li>
              <li>
                <a
                  href="#eventsundvideo"
                  title="bla"
                  className="deformed bookmarks"
                >
                  Events & Video
                </a>
              </li>
              <li>
                <a href="#interview" title="bla" className="deformed bookmarks">
                  Interview
                </a>
              </li>
              <li>
                <a href="#podcast" title="bla" className="deformed bookmarks">
                  Podcast
                </a>
              </li>
              <li>
                <a href="#sprecher" title="bla" className="deformed bookmarks">
                  Sprecher
                </a>
              </li>
              <li>
                <a href="#musik" title="bla" className="deformed bookmarks">
                  Fast Forward ⇥
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <article className="article" id="radio">
          <h2>Punchline mit Kevin</h2>
          <h1>Radio</h1>
          <p>
            Als Radiomoderator bin ich zweiwöchentlich mittwochs ab 20 Uhr auf
            Bremen Next zu hören. Sendungsschwerpunkt ist der vollumfängliche
            Blick auf das Genre Rap. Einordnung und Darstellung spielen hierbei
            ebenso eine wichtige Rolle wie die interne & externe
            Ausseinandersetzung mit der Hip Hop Kultur und ihren
            Protagonist:innen.
          </p>
          <a
            href="https://open.spotify.com/playlist/6mhEFsA2CO73tAbXfuqhNz?si=ofP2AGdYQx2N2H4jc6DyEA"
            className="imagelink"
          >
            <div className="clip-text">
              <span>Click to Spotify</span>
            </div>

            <Tilt
              className="tilt-img"
              tiltMaxAngleX={40}
              tiltMaxAngleY={40}
              perspective={300}
              scale={2}
              transitionSpeed={5000}
              gyroscope={true}
            >
              <Image
                fluid={data.ImageBesserePlaylist.childImageSharp.sizes}
                alt="CD"
                className="inner-element"
              />
            </Tilt>
          </a>
          <p>
            Mit dieser Spezialsendung habe ich das große Glück Musik &
            Moderation nach meinen Vorstellungen zu handhaben. Da findet dann
            auch der fünf-minütige Ausflug in die Welt der Drumsoundsamples ebenso
            statt wie die einstündige Listening Session mit bekannten und
            unbekannten Vertretern des Genres.
          </p>
        </article>
        <article className="article" id="eventsundvideo">
          <h2>Durch die Nacht mit</h2>
          <h1>Events & Video</h1>
          <p>
            Festivals, Dokumentationen, Berichte, Gala, Podiumsdiskussion,
            Veranstaltungen im Kultur & Sportbereich und noch vieles mehr habe
            ich in den letzten Jahren wegmoderiert als gäbe es kein morgen.
          </p>
          <p>
            Neben meiner Aufgabe als Moderator habe ich dabei häufig auch
            Aufgaben der Stage Hand mitübernommen, da mir eine ganzheitliche
            Event-Erfahrung für das Publikum wichtiger ist als meine Rolle.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Hire me
          </a>
        </article>
        <article className="article" id="interview">
          <h2>Watt hast du gesagt?</h2>
          <h1>Interview</h1>
          <p>
            Kein Frage - Antwort Spielchen sondern Gespräche auf Augenhöhe. Das
            ist der Anspruch mit dem ich in jedes Interview gehe.
          </p>
          <p>
            Egal ob politische Podiumsdiskussion, Zwiegespräch im Studio oder in
            schöner Kulisse vor laufenden Kameras. Mein ehrliches Interesse an
            Menschen und ihren Geschichten ist unabhängig von Format und Medium.
          </p>
          <p>
            Meine Interviews und Moderationstätigkeiten werden regelmäßig von
            1-Live Moderatorin Tina Middendorf abgehört und in Zusammenarbeit
            mit mir weiter ausgearbeitet. Auch Trainings von und mit Andreas
            Neumann helfen mir, mich fortlaufend weiter zu entwickeln.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Fragen stellen
          </a>
        </article>
        <article className="article" id="podcast">
          <h2>Gefährliches Halbwissen</h2>
          <h1>Podcast</h1>
          <p>
            Weit vor jeder Hypewelle haben sich Niklas Barning & Florenz
            Heldermann zusammengesetzt und den Podcast{" "}
            <a
              href="https://halbwissen.co/"
              title="Webseite des Podcast Gefährliches Halbwissen"
            >
              "Gefährliches Halbwissen"
            </a>
            ins Netz gestellt. Ab Folge fünf, von mittlerweile über 100, bin ich
            fester Bestandteil des Ensembles.
          </p>
          <p>
            Thematisch hat sich der Podcast im Laufe der Zeit stark gewandelt.
            Anfänglich setzte sich er sich vorallem mit den Leben der drei
            Hosts, Meinungen zu Nachrichten, Popkulturellem, Bremen bezogenem
            und allerlei anderen Kuriositäten wie zum Beispiel der{" "}
            <a
              href="https://de.wikipedia.org/wiki/Hans-Insel"
              title="Hans Insel"
            >
              Hans Insel
            </a>{" "}
            auseinander.
          </p>
          <p>
            Heutzutage casten{" "}
            <a
              href="https://www.instagram.com/mihatory/"
              title="Instagram Account von Mihaela Sartori"
            >
              Mihaela Sartori
            </a>
            ,{" "}
            <a
              href="https://www.instagram.com/psassa__/"
              title="Instagram Account von PSASSA"
            >
              Pascal Seul (aka PSASSA)
            </a>
            ,{" "}
            <a
              href="https://niklasbarning.de/"
              title="Webseite & Blog von Niklas Barning"
            >
              Niklas
            </a>{" "}
            und ich vorallem um mit der geschaffenen Reichweite Bremer:innen und
            ihr Tagewerk vorzustellen. Denn: Bremen hat ein wahnsinniges
            Potential nur leider sprach bisher niemand darüber.
          </p>
          <h4>Bisherige Meilensteine:</h4>
          <ul>
            <li>iTunes Podcastcharts Platz 1</li>
            <li>über 20.000 Hörer auf einer einzigen Folge</li>
            <li>erster Podcast auf einem Festival</li>
            <li>Durchschnittlich 7.000 Hörer:innen pro Folge</li>
          </ul>
          <a href="mailto:moin@kevinheil.de" className="button">
            Miteinander schnacken?
          </a>
        </article>
        <article className="article" id="sprecher">
          <h2>Aus dem Off</h2>
          <h1>Sprecher</h1>
          <p>
            Derzeit lasse ich mich vom Chefsprecher von Radio Bremen, Holger
            Postler, nebenberuflich zum professionellen Sprecher ausbilden.
          </p>
          <p>
            Mein Ziel ist, es irgendwann als Synchronsprecher arbeiten zu
            können, in Hör- & Videospielen Charakteren meine Stimme zu leihen
            und als Offsprecher Dokumentationen zu vertonen.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Stimme leihen
          </a>
        </article>
      </section>

      <section id="musik">
        <header className="sectionheader sectionheader--3">
          <h1>Musik</h1>
          <p>
            Als stetiger Wegbegleiter meines Lebens war es nur eine Frage der Zeit
            bis ich auch die Welt der Musik professioneller betrete als nur
            zuzuhören. Da ich aber keinerlei Talente für Instrumente besitze und
            auch Singen nur maximal mir Spaß macht (vgl.{" "}
            <a href="https://www.youtube.com/watch?v=vl3OTD0pWCA">
              Dendemann - Gut und gerne
            </a>
            ) hat dieser Schritt länger gedauert als mir lieb ist.
          </p>
          <p>
            Doch durch meine Affinität zur Show und Bühne sowie meine Erfahrung
            als Moderator kann ich als perfektes Bindeglied zwischen DJ und
            Crowd agieren und habe so die ersten aktiven Schritte in der
            Musikwelt machen dürfen. Festivals wie Deichbrand, Kitesurf Masters,
            Breminale oder Veranstalter wie Radio Bremen, Bremen Next, Redbull
            uvm. boten hierfür die Bühne.
          </p>
          <p></p>

          <nav>
            <ul>
              <li>
                <a
                  href="#mc"
                  title="Master of Ceremony"
                  className="deformed bookmarks"
                >
                  Master of Ceremony
                </a>
              </li>
              <li>
                <a href="#hyped" title="Hyped" className="deformed bookmarks">
                  Hyped
                </a>
              </li>
              <li>
                <a href="#nav" title="bla" className="deformed bookmarks">
                  All the way up ↥
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <article className="article" id="mc">
          <h2>Auf die Fresse Rap jetzt gibts Heckmeck</h2>
          <h1>Master of Ceremony</h1>
          <p>
            2017 kamen DJ Smiles und ich recht spontan auf die Idee das Publikum
            der Breminale während seiner Headliner Show weiter anzuheizen. Da
            ich ohnehin die Bühnenmoderation an diesem Tag inne hatte wurde der
            spontane Plan umgesetzt und war ein Riesenerfolg.
          </p>
          <p>
            Mittlerweile ist daraus ein fester Bestandteil der Bremen Next Show
            geworden und ich durfte seitdem auf diversen Bühnen mit DJs wie zum
            Beispiel{" "}
            <a
              href="https://www.instagram.com/dj_rafik/"
              title="Instagram Seite von DJ Rafiq"
            >
              Rafiq
            </a>
            ,{" "}
            <a
              href="https://www.instagram.com/djhandson/"
              title="Instagram Seite von DJ Handson"
            >
              Handson
            </a>
            ,{" "}
            <a
              href="https://www.instagram.com/djkaylabofficial/"
              title="Instagram Seite von DJ Kaylab"
            >
              Kaylab
            </a>{" "}
            oder{" "}
            <a
              href="https://www.instagram.com/djjimmyderoy/"
              title="Instagram Seite von DJ Jimmy Deroy"
            >
              Jimmy Deroy
            </a>{" "}
            auftreten.
          </p>
          <p>
            Der Begriff Master of Ceremony oder MC ist dabei im ursprünglichsten
            aller Bedeutungen zu verstehen. Wie bei einem Soundsystem oder im
            Hip Hop Ende der 70er / Anfang der 80er Jahre stehe ich als MC auf
            der Bühne, um das Publikum anzuheizen, Interaktionen durchzuführen
            und gegebenenfalls Werbung für Festival/DJ/Support oder ähnliches zu
            machen.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Book me
          </a>
        </article>
        <article className="article" id="hyped">
          <h2>Verkleidet als Youtuber</h2>
          <h1>Hyped</h1>
          <p>
            Mich mit Musik auseinander-zu-setzen, einzelne Tracks oder Alben wie
            mit einem Skalpell zu sezieren, um sie zu analysieren und mich mit
            anderen darüber auszutauschen, war schon immer ein großes Hobby.
          </p>
          <a
            href="https://www.youtube.com/c/BremenNEXT/videos"
            title="Bremen Next Youtube Kanal"
            className="imagelink"
          >
            <div className="clip-text">
              <span>Click to YouTube</span>
            </div>

            <Tilt
              className="tilt-img"
              tiltMaxAngleX={40}
              tiltMaxAngleY={40}
              perspective={300}
              scale={2}
              transitionSpeed={5000}
              gyroscope={true}
            >
              <Image
                fluid={data.ImageHyped.childImageSharp.sizes}
                alt="CD"
                className="inner-element"
              />
            </Tilt>
          </a>
          <p>
            Nach vielen Monaten der Planung, der Testdrehs und des immer wieder
            über Board werfens von guten Ideen, ist am 26.07.2020 mein eigenes
            Musikanalyse Format gestartet.
          </p>
          <p>
            Bei Hyped besprechen Karina & ich im wöchentlichen Wechsel Themen
            aus der Musikwelt mit dem Schwerpunkt auf der Hip Hop und R'n'B
            Kultur.
          </p>

          <a
            href="https://www.youtube.com/c/BremenNEXT/videos"
            title="Bremen Next Youtube Kanal"
            className="button"
          >
            Get your Facts straight
          </a>
        </article>
        {/* <article className="article" id="hitzundkunzt">
          <h2>Geplanter Wahnsinn</h2>
          <h1>Hitz&Kunzt</h1>
          <p>
            Nach einem gemeinsamen Auftritt bei den Kitesurf Masters 2019 ist
            binnen weniger Wochen eine beständige Konstellation gewachsen in der
            DJ Handson und ich den Wahnsinn eines Open Air bzw. eines Festivals
            in eine Clubshow übertragen.
          </p>
          <p>
            Auf der anderen Seite sind wir als Duo beständig dabei die
            Spontanität eines Livesets planbarer zu machen, darum herum eine
            Show zu entwickeln und im Endeffekt dem Gast sowie den Veranstaltern
            ein festes, gleichbleibend gutes Endprodukt liefern zu können.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Book us
          </a>
        </article> */}
      </section>
      {/* <span className="coordinate">
        53° 4' 45.466" N 8° 48' 6.097" E 
      </span> */}

      <section className="abbinder">
        <article className="article">
          <h2>Wall of Text</h2>
          <h1>TL;DR</h1>
          <p>
            Das ist jetzt alles viel Text und vorallem viele verschiedene
            Bereiche. Solltest du dich aber in irgendeiner Form angesprochen
            fühlen, melde dich gern.
          </p>
          <p>
            Egal ob es bei der Anfrage um die Entwicklung eines
            Prototypen/&shy;Webshop/&shy;App/&shy;Webseite/&shy;whatever geht oder um die Moderation
            einer abendfüllenden Fernsehgala. Bisher gab es noch kein Projekt,
            auf das ich nicht eine Lösung wusste oder gefunden habe.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Kontakt
          </a>
        </article>

        {/* <article className="article">
          <h2>Die unendliche Geschichte</h2>
          <h1>#Todos</h1>
          <p>
            Das ist jetzt alles viel Text und vorallem viele verschiedene
            Bereiche. Solltest du dich aber in irgendeiner Form angesprochen
            fühlen, melde dich gern.
          </p>
          <p>
            Egal ob es bei der Anfrage um die Entwicklung eines
            Prototypen/Webshop/App/Webseite/whatever geht oder um die Moderation
            einer abendfüllenden Fernsehgala. Bisher gab es noch kein Projekt
            auf das ich nicht eine Lösung wusste oder gefunden habe.
          </p>
          <a href="mailto:moin@kevinheil.de" className="button">
            Kontakt
          </a>
        </article> */}
      </section>
    </Layout>
  )
}

export default IndexPage
